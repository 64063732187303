import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import styled from '@emotion/styled';
import type { HeroSpacing_contentBlock$key } from './__generated__/HeroSpacing_contentBlock.graphql';

const contentBlockFragment = graphql`
  fragment HeroSpacing_contentBlock on HeroSpacingBlock {
    spacingSize
  }
`;

const SpacingContainer = styled.div({
  height: `var(--spacing)`,
});

type HeroSpacingProps = {
  contentBlock: HeroSpacing_contentBlock$key;
};

const HeroSpacing: FC<HeroSpacingProps> = ({ contentBlock }) => {
  const { spacingSize } = useFragment(contentBlockFragment, contentBlock);

  return <SpacingContainer data-testid="spacing-container" style={{ ['--spacing' as string]: spacingSize + 'px' }} />;
};

export default HeroSpacing;
